<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Accordion class="accordion-custom" :activeIndex="0">
                    <AccordionTab>
                        <template #header>
                            <i class="pi pi-calendar m-2"></i>
                            <span>Resumo de agendamentos do Cliente</span>
                        </template>

                        <div>
                            <span>Selecione o cliente</span>
                            <DropdownClienteInscricao
                                v-if="!form?.id"
                                id="customer_id"
                                :loading="loadingCustomer"
                                :disabled="loadingCustomer"
                                class="w-full mb-1"
                                v-model="form.customer"
                                :idValue="form.customerId"
                                :class="{ 'p-invalid': submitted && !form.customerId }"
                            />
                            <span>Data Inicio</span>
                            <Calendar class="w-full mb-1" v-model="dataInicio" />
                            <span>Data Fim</span>
                            <Calendar class="w-full mb-3" v-model="dataFim" />
                        </div>
                        <div class="mb-3">
                            <Button type="m-3 button" label="Gerar Resumo" icon="pi pi-search" :loading="loading" @click="loadResumoAgendamentos" />
                        </div>
                        <div class="mb-3 card">
                            <div v-if="carregarResumoAgendamento">
                                <h3>Hummm.. deixa eu analisar, um momento!</h3>
                                <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
                            </div>
                            <h4>{{ resumoAgendamento }}</h4>
                        </div>
                    </AccordionTab>
                    <AccordionTab>
                        <template #header>
                            <i class="pi pi-user m-2"></i>
                            <span>Validação de Descricao de Cargo</span>
                        </template>
                        <div>
                            <span>Informe o nome do cargo</span>
                            <InputText class="w-full mb-3" v-model="nomeCargo" />

                            <span>Informe a descricao do cargo</span>
                            <Textarea class="w-full mb-3" v-model="descricaoCargo" />
                        </div>
                        <div>
                            <Button type="mb-3 button" label="Analisar" icon="pi pi-search" :loading="loading" @click="loadVerificarDescricaoCargo" />
                        </div>

                        <div class="mb-3 card">
                            <div v-if="carregarDescricaoCargo">
                                <h3>Hummm.. deixa eu analisar, um momento!</h3>
                                <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
                            </div>
                            <h4>{{ resumoDescricaoCargo }}</h4>
                        </div>
                    </AccordionTab>
                    <AccordionTab>
                        <template #header>
                            <i class="pi pi-search m-2"></i>
                            <span>Verificar postura ergonômica</span>
                        </template>

                        <div class="mb-3">
                            <Button type="mb-3 button" label="Selecionar Anexo" icon="pi pi-search" :loading="loading" @click="anexos" />
                            <AppAnexoDialog
                                v-if="showAnexoDialog"
                                v-model:visible="showAnexoDialog"
                                title="Anexos do Ergonômico"
                                tipo="FOTO_POSTURA_ERGONOMICA"
                                origem="OPENIA"
                                v-model:origemId="recordAnexos"
                                @onClickCloseAnexo="close"
                            >
                            </AppAnexoDialog>
                        </div>
                        <div class="mb-3">
                            <Button type="mb-3 button" label="Analisar Imagem" icon="pi pi-search" :loading="loading" @click="loadVerificarPosturaErgonomica" />
                        </div>
                        <div class="mb-3 card">
                            <div v-if="carregarPosturaErgonomica">
                                <h3>Hummm.. deixa eu analisar, um momento!</h3>
                                <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
                            </div>
                            <h4>{{ resumoPosturaErgonomica }}</h4>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    </div>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getClientBase } from '@/services/http';
import DropdownClienteInscricao from '../clientes/components/DropdownClienteInscricao.vue';
export default {
    components: { DropdownClienteInscricao },
    data() {
        return {
            loadingCustomer: false,
            resumoAgendamento: null,
            resumoDescricaoCargo: null,
            resumoPosturaErgonomica: null,
            carregarResumoAgendamento: false,
            carregarDescricaoCargo: false,
            carregarPosturaErgonomica: false,
            dataInicio: null,
            dataFim: null,
            form: [],
            showAnexoDialog: false,
            recordAnexos: null,
            url: null
        };
    },
    created() {
        this.$serviceCustomer = new BaseService('/customers/combo');
    },
    async mounted() {
        this.$serviceCustomer = new BaseService('customers/combo');
        this.loadCustomers();
    },
    methods: {
        async loadCustomers() {
            const { data } = await this.$serviceCustomer.findAll({});
            this.customers = data;
        },
        async loadResumoAgendamentos() {
            this.carregarResumoAgendamento = true;
            const dto = {
                id: this.form.customer.id,
                dataInicio: this.dataInicio,
                dataFim: this.dataFim
            };
            const resumoAgendamento = await getClientBase().post(`open-ia/resumo-agendamento`, dto);
            this.resumoAgendamento = resumoAgendamento.data;
            this.carregarResumoAgendamento = false;
        },
        async loadVerificarDescricaoCargo() {
            this.carregarDescricaoCargo = true;
            const dto = {
                nomeCargo: this.nomeCargo,
                descricaoCargo: this.descricaoCargo
            };
            const resumoDescricaoCargo = await getClientBase().post(`open-ia/validar-descricao-cargo`, dto);
            this.resumoDescricaoCargo = resumoDescricaoCargo.data;
            this.carregarDescricaoCargo = false;
        },
        async loadVerificarPosturaErgonomica() {
            this.carregarPosturaErgonomica = true;
            const dto = {
                url: this.url
            };
            const resumoPosturaErgonomica = await getClientBase().post(`open-ia/validar-posicao-ergonomica`, dto);
            this.resumoPosturaErgonomica = resumoPosturaErgonomica.data;
            this.carregarPosturaErgonomica = false;
        },
        anexos() {
            if (!this.recordAnexos) {
                this.recordAnexos = Math.floor(10 * Math.random() * 60);
            }
            this.showAnexoDialog = true;
        },
        async close() {
            this.showAnexoDialog = false;
            const record = await getClientBase().get(`anexo/OPENIA/${this.recordAnexos}`);
            this.url = record.data[0].url
        }
    }
};
</script>